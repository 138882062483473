<template>
  <div>
    <v-container class="px-6 py-6" fluid>
      <v-row>
        <v-col md="12">
          <status-highway />
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col class="pt-0 mb-lg-0 mb-10 mt-15" lg="4" v-for="(type, index) in types" :key="index">
          <incidents-type-counter :title="type.name" :code="type.code" :type="type.id"></incidents-type-counter>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import StatusHighway from "@/views/Dashboard/Widgets/StatusHighway";
// import CardWebsiteViews from "./Widgets/CardWebsiteViews.vue";
// import CardDailySales from "./Widgets/CardDailySales.vue";
import IncidentsTypeCounter from "@/views/Dashboard/Widgets/IncidentsTypeCounter";
import incidentService from "@/services/incident.service";

export default {
  name: "Dashboard",
  components: {
    StatusHighway,
    // CardWebsiteViews,
    // CardDailySales,
    IncidentsTypeCounter,
  },
  data() {
    return {
      types:[]
    };
  },
  mounted() {
    this.getTypes()
  },
  methods:{
    getTypes(){
      incidentService.getTypes().then(d =>{
        this.types = d.items;
      })
    }
  }
};
</script>
