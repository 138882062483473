<template>
  <v-card
      :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
      class="card-shadow border-radius-xl px-4 py-5"
      :loading="false"
  >
    <div
        class="
        bg-gradient-default
        shadow-dark
        border-radius-lg
        py-3
        pe-1
        mb-6
        mt-n12
      "
    >
      <div class="chart-area">
        <canvas
            :id="`chart-${type}`"
            :height="$route.name == 'VrDefault' ? '370' : '170'"
        >
        </canvas>
      </div>
    </div>
    <h6
        :class="$route.name == 'VrDefault' ? 'text-white' : 'text-typo'"
        class="ms-2 mt-6 mb-0 text-h6 font-weight-bold"
    >
      {{title}}
    </h6>
    <p class="text-sm ms-2 text-body font-weight-light">
      {{code}}
    </p>
    <hr class="horizontal dark mb-4 mx-2"/>
    <div class="d-flex text-body mx-2">
      <i class="material-icons-round text-sm my-auto me-1">schedule</i>
      <p class="mb-0 text-sm font-weight-light text-body">Últimos 6 meses</p>
    </div>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";
import incidentService from "@/services/incident.service";

export default {
  name: "IncidentsTypeCounter",
  props:{
    type:{
      type: Number,
      default: null
    },
    title:{
      type: String,
      default: "None"
    },
    code:{
      type: String,
      default: "None"
    },
  },
  data: function () {
    return {
      tasksViewsID: "tasksViews",
    };
  },
  mounted() {
    this.getData()
  },
  methods:{
    getData(){
      incidentService.getIncidentsTypeCounter(this.type).then(d =>{
        new Chart(document.getElementById(`chart-${this.type}`).getContext("2d"), {
          type: "line",
          data: {
            labels: d.labels,
            datasets: [
              {
                label: "Incidencias",
                tension: 0,
                pointRadius: 5,
                pointBackgroundColor: "rgba(255, 255, 255, .8)",
                pointBorderColor: "transparent",
                borderColor: "rgba(255, 255, 255, .8)",
                borderWidth: 4,
                backgroundColor: "transparent",
                fill: true,
                data: d.values,
                maxBarThickness: 6,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                  color: "rgba(255, 255, 255, .2)",
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#f8f9fa",
                  font: {
                    size: 14,
                    weight: 300,
                    family: "Roboto",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  color: "#f8f9fa",
                  padding: 10,
                  font: {
                    size: 14,
                    weight: 300,
                    family: "Roboto",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
            },
          },
        });
      })
    }
  }
};
</script>
