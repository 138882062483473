<template>
  <v-card
      :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
      class="card-shadow border-radius-xl"
  >
    <div class="px-6 pt-4 pb-0 d-flex">
      <v-avatar
          class="shadow"
          color="primary border-radius-xl mt-n8"
          height="64"
          width="64"
      >
        <v-icon class="material-icons-round text-white" size="24">
          language
        </v-icon
        >
      </v-avatar>
      <p
          :class="$route.name == 'VrDefault' ? 'text-white' : 'text-typo'"
          class="font-weight-bold text-h6 mb-0 ms-3"
      >
        Valores por Autopista
      </p>
    </div>
    <v-row>
      <v-col lg="6" md="7">
        <v-card-text class="py-3">
          <v-list class="py-0 bg-transparent">
            <v-list-item
                v-for="(item, index) in counters"
                :key="item.highwayName"
                :class="{ 'border-bottom': index != counters.length - 1 }"
                class="px-4 py-1"
            >
              <v-list-item-content>
                <span
                    :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                    class="text-xs font-weight-bold"
                >
                  Autopista:
                </span>
                <v-list-item-title
                    :class="'text-typo'"
                    class="text-sm mb-0"
                    v-text="item.highwayName"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="text-center">
                <span
                    :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                    class="text-xs font-weight-bold"
                >
                  Abiertas:
                </span>
                <v-list-item-title
                    :class="'text-typo'"
                    class="text-sm mb-0"
                    v-text="item.openCount"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="text-center">
                <span
                    :class="'text-body'"
                    class="text-xs font-weight-bold"
                >
                  Atendidas:
                </span>
                <v-list-item-title
                    :class="'text-typo'"
                    class="text-sm mb-0"
                    v-text="item.attendedCount"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="text-center">
                <span
                    :class="'text-body'"
                    class="text-xs font-weight-bold"
                >
                  Rechazadas:
                </span>
                <v-list-item-title
                    :class="'text-typo'"
                    class="text-sm mb-0"
                    v-text="item.rejectedCount"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-col>
      <v-col lg="6" md="5">
        <google-map id="map" ref="Map" :center="center" :zoom="4" >
          <google-map-marker
              v-for="(marker, index) in markers"
              :position="marker.position"
              :key="index"
              :clickable="true"
              @click="toggleInfoWindow(marker)"
          />          
        </google-map>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/maps/world-merc";
import "jsvectormap/dist/css/jsvectormap.min.css";
import incidentService from "@/services/incident.service";

export default {
  name: "StatusHighway",
  data() {
    return {
      infoWindow: {
        position: {
          lat: 44.2899,
          lng: 11.8774
        }
      },
      center: {
        lat: 19.432608,
        lng: -99.133209
      },
      counters: [],
      markers: [],
    };
  },
  watch:{
    markers(markers) {
      const bounds = new google.maps.LatLngBounds()
      for (let m of markers) {
        bounds.extend(m.position)
      }
      this.$refs.Map.fitBounds(bounds)
    }
  },
  mounted() {
    this.getCounters();
    this.getMarkers();
  },
  methods:{
    getCounters(){
      incidentService.getIncidentsStatusCounter().then(d =>{
        this.counters = d
      })
    },
    getMarkers(){
      incidentService.getIncidents({
        SkipCount: 0,
        MaxResultCount: 100,
      }).then(d =>{
        this.markers = d.items.filter(i => i.latitude !== 0 && i.longitude !== 0).map(i => {
          i.position = {
            lat: i.latitude,
            lng: i.longitude
          };
          
          return i;
        });
      })
    },
    toggleInfoWindow(item){
      alert(`Autopista: ${item.highway.name}\n` +
          `Estatus: ${item.side.name}\n` +
          `Origen: ${item.origin}\n` +
          `Folio: ${item.folio}\n` +
          `Comentarios: ${item.initialComment}\n`
      )
    }
  }
};
</script>
